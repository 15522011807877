<template>
  <Loading :active="loading" />
  <div class="login-dashboard">

    <!-- LOGIN ADMIN NORMAL -->
    <form class="login-dashboard-card" v-if="!isSingleSignOn" @submit.prevent="validateToken()">
      <img src="/logo-color.png" style="width:100px;" alt="AdminLTE Logo" />
      <h3>Iniciar Sesión</h3>
      <p v-for="(error, index) in errorsLogin" :key="index">{{ error }}</p>
      <div class="login-dashboard-card-input">
        <input
          type="text"
          v-model="email"
          autocomplete="false"
          placeholder="Correo"
        />
        <input
          type="password"
          v-model="password"
          autocomplete="false"
          placeholder="Contraseña"
        />
      </div>
      <button
        type="submit"
        class="login-dashboard-card-btn btn-default"
      >
        Ingresar
      </button>
    </form>


    <!-- AUTENTICACION SSO -->
    <section class="container-sso-login"  v-if="isSingleSignOn && !showForgetPassword">
        <img :src="config.images+'/logo.svg'" alt="AdminLTE Logo" class="logoPrimax d-none" />
        <h3>Iniciar Sesión</h3>
        <p v-for="(error, index) in errorsLogin" :key="index">* {{ error }}</p>
          <input
            type="text"
            v-model="email"
            autocomplete="false"
            placeholder="Correo"
          />
          <input
            type="password"
            v-model="password"
            autocomplete="false"
            placeholder="Contraseña"
          />
          <button @click="validateToken()" >
              Ingresar
          </button>
          <a @click="ShowForgetPassword()" >Recordar Contraseña</a>
    </section>

    <!-- REMEMBER PASSWORD -->
    <section class="container-sso-login" v-if="isSingleSignOn && showForgetPassword">
      <h3>Recordar contraseña</h3>
      <p class="mt-2" v-if="errorsForgetPass.length == 0">
        Se te enviara un email con la nueva contraseña, ingresa tu correo.
      </p>
       <p v-for="(error, index) in errorsForgetPass" :key="index"> * {{ error }}</p>
        <input
          type="text"
          v-model="emailPassword"
          autocomplete="false"
          placeholder="Correo"
        />
      <button @click="forgetPassword(emailPassword)" >
        Enviar Email
      </button>      
      <a @click="showForgetPassword = false"> Login </a>
    </section>

    <!-- AUTENTICACION SSO -->
    <div class="login-dashboard-card sso d-none" v-if="isSingleSignOn && !showForgetPassword">
        <img :src="config.images+'/logo.svg'" alt="AdminLTE Logo" class="brand-image" />
            <h3>Iniciar Sesión</h3>
            <p v-for="(error, index) in errorsLogin" :key="index">{{ error }}</p>
            <div class="login-dashboard-card-input">
              <input
                type="text"
                v-model="email"
                autocomplete="false"
                placeholder="Correo"
              />
              <input
                type="password"
                v-model="password"
                autocomplete="false"
                placeholder="Contraseña"
              />
            </div>
            <button
              class="login-dashboard-card-btn btn-default"
              @click="validateToken()"
            >
              Ingresar
            </button>
            <a @click="ShowForgetPassword()" class="forgetPass">Recordar Contraseña</a>
    </div>

    <!-- SECCION OLVIDE LA CONTRASEÑA -->
     <div class="login-dashboard-card sso d-none" v-if="isSingleSignOn && showForgetPassword">
        <img :src="config.images+'/logo.svg'" alt="AdminLTE Logo" class="brand-image" />
            <h3>Ingrese el email</h3>
            <p class="mt-2">
              Se te enviara un email con la nueva contraseña, ingresa tu correo.
            </p>
            <p v-for="(error, index) in errorsForgetPass" :key="index">* {{ error }}</p>
            <div class="login-dashboard-card-input">
              <input
                type="text"
                v-model="emailPassword"
                autocomplete="false"
                placeholder="Correo"
              />
            </div>
            <button
              class="login-dashboard-card-btn btn-default"
              @click="forgetPassword(emailPassword)"
            >
              Enviar Email
            </button>      
    </div>

  </div>

    <Toast
            style="margin: 3%"
            :message="toastMessage"
            v-model:active="toastActive"
            :type="toastType"
          />

</template>
<script>
import userService from "@/services/userService";
import Loading from "../components/Loading";
import config from "@/config/config";
import Toast from "../components/Toast";
export default {
  name: `Login`,
  data() {
    return {
      email: "",
      password: "",
      errorsLogin: [],
      errorsForgetPass: [],
      loading: false,
      config: config.getConfig(),
      showForgetPassword: false,
      toastType: "",
      toastActive: false,
      toastMessage: "",
    };
  },
  components: { Loading, Toast},
  computed: {
    // a computed getter
    isSingleSignOn: function () {
      const queryString = window.location.search.substring(1);
      console.log(queryString && queryString == 'sso', ' -------------------->>>> ',queryString)
      return queryString && queryString == 'sso';
    }
  },
  async beforeRouteEnter(to, from, next) {
     const queryString = window.location.search.substring(1);
     const isSso = (queryString && queryString == 'sso');
    const isAuthenticated = await userService.verifiLogin();
    if (to.name !== "Dashboard" && isAuthenticated && !isSso) {
      next({ name: "Dashboard" });
    } else {
      next();
    }
  },
  async mounted(){
    const isAuthenticated = await userService.verifiLogin();
    console.log(userService.config.apiUrl, ' here --->> ',isAuthenticated)
    if(this.isSingleSignOn && isAuthenticated)
    {
      const token = await userService.getToken();
      const user = await userService.getUsersData();
      console.log(user, ' heree --->> ',token)
    }
     
  },
  methods: {
   /*  prueba(){
  window.top.postMessage(JSON.stringify(data), '*')
    }, */
    singleSignOn(res) {
      const {user ,accesstoken} = res.response;
      const data = {userId:user.id, api:userService.config.apiUrl, token: accesstoken};
      //alert('sign in with single sign on');
      window.top.postMessage(JSON.stringify(data), '*')
      /* userService.saveToken(res.response.accesstoken); */
    },
    async validateToken() {
      this.loading = true;
      let res = null

      try {
        res = await userService.login({
          email: this.email,
          password: this.password,
        });
      } catch (e) {
        this.toastType = "error"
        this.toastActive = true
        this.toastMessage = `Error al procesar la información: "${e.message}"`

        throw e
      } finally {
        this.loading = false;
      }

      if (res.error || (res.code && res.code !== 200)) {
        this.errorsLogin = [];
        for (const e in res.error) {
          console.log(res.error[e]);
          this.errorsLogin.push(res.error[e][0]);
        }

        if (res.response) {
          this.errorsLogin.push(res.response);
        }

        this.loading = false;
        return;
      }

      if (this.isSingleSignOn) {
        console.log('single', this.isSingleSignOn)
        this.singleSignOn(res);
      } else {
        console.log('nolmal', this.isSingleSignOn)
        this.normalLoginAdmin(res)
      }
    },

    normalLoginAdmin(res){
      if (res.response.user.role === "admin") {
          userService.saveToken(res.response.accesstoken);
          this.loading = false;
          this.$router.push("/dashboard");
        } else {
          this.errorsLogin = [];
          this.errorsLogin.push("No tiene permisos de administrador");
          this.loading = false;
        }
    },
    ShowForgetPassword(){
      this.showForgetPassword = true
    },
    async forgetPassword(email){
      console.log(email)
      this.loading = true;
      const res = await userService.forgetPassword(email);
      if (res.error || (res.code && res.code !== 200)) {
        this.errorsForgetPass = [];
        for (const e in res.error) {
          console.log(res.error[e]);
          this.errorsForgetPass.push(res.error[e][0]);
        }
        if (res.response) {
          this.errorsForgetPass.push(res.response);
        }
        this.loading = false;
        return;
      }
      this.successMessage();
      this.loading = false;
      this.showForgetPassword = false;
    },
    successMessage(message = "Correo Enviado Exitosamente"){
      this.toastType = "success";
      this.toastMessage = message;
      this.toastActive = true;
    }
  },
};
</script>
<style lang="scss">
@import "@/style.scss";
.login-dashboard {
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    z-index: 1;
    top: -5em;
  }
  &-card {
    position: relative;
    color: $orangeCS;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3em;
    border: 1px solid $orangeCS;
    border-radius: 4px;
    background: white;
    padding-bottom: 4em;
    max-width: 330px;
    width: 100%;
    &.sso {
      border: none;
      h3 {
        font-size: 30px;
        color: #666666;
      }

      input {
        border-radius: 10px;
      }
    }
    h3 {
      font-size: 21px;
      margin-bottom: 1em;
    }
    p {
      font-size: 12px;
      text-align: center;
    }
    &-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        color: $orangeCS;
      }

      input {
        border: 1px solid #dbdbdb;
        padding: 0.5em 1em;
        margin-bottom: 0.5em;
        &:focus {
          outline-color: $orangeCS;
        }
      }
    }

    &-btn {
      position: absolute;
      bottom: 0;
      padding: 0.8em 2em;
      border: none;
      border-top: 1px solid $orangeCS;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      margin-top: 1em;
      width: 100%;
      border-radius: unset;
      &:focus {
        outline: none;
      }
    } 
  }
  .forgetPass{
    cursor: pointer;
  }
  // ? Styles just for sso login
  .container-sso-login {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 15px 10px;
    h3 {
      text-align: center;
      font-size: 30px;
      color: #666;
    }
    input {
      padding: 15px;
      border: 1px solid #F6F6F6;
      background: #F6F6F6;
      border-radius: 10px;
      margin: 15px 0;
      &:focus  {
        // outline: 0;
        outline-color: #FF6900;
      }
    }

    button {
      padding: 15px;
      background: #FF6900;
      color: #fff;
      margin: 15px 0;
      border-radius: 30px;
      border: none;
      cursor: pointer;
    }
    p {
      color: #FF6900;
      font-size: 14px;
      margin-bottom: 5px;
    }
    a {
      text-align: center;
      color: #BDBDBD;
      font-weight: 400;
      margin-top: 5px;
      cursor: pointer;
      transition: .3s;
      &:hover {
        color: #FF6900;
      }
    }
  }
}
</style>
