<template >
  <div
    v-if="active"
    class="alert alert-dismissible fade show"
    :class="{
      'alert-warning': type === 'error',
      'alert-success': type === 'success',
    }"
    role="alert"
  >
    {{ message }}
    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      @click="closeToast()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>
<script >
export default {
  name: `Toast`,
  props: {
    type: String,
    message: String,
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeToast() {
      this.$emit("update:active", false);
    },
  },
  watch: {
    active(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.closeToast();
        }, 1500);
      }
    },
  },
};
</script>
<style lang="scss">
.alert {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 30%;
  right: 1em;
}
</style>